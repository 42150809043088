<template>
	<div class="info-content">
		<Temporary />
		<div>
			<ul style="padding-left:24px;">
				<li>
						ใช้ใน Lot ไหน เท่าไหร่
				</li>
				<li>
						ข้อมูลต้อง merge กันเยอะ เลยรอก่อน
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// TODO Part Usage In Lot
import Temporary from "@components/common/Temporary.vue"
export default {
	components : {
		Temporary
	} ,
	props : {
		part : {
			type : null,
			default: ()=>[]
		}
	}
}
</script>
<style lang="less" scoped>
.info-content {
	padding : 16px;
}
</style>
